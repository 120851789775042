.banner-section {
    // margin-left: -$px10;
    // margin-right: -$px10;
    position: relative;
    figure {
        position: relative;
        overflow: hidden;
        height: calc(50vh - 76px);
        @include bp-above(lg) {
            height: calc(100vh - 76px);
        }
        @include bp-above(xl) {
            height: 100vh;
        }
        &::after {
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
            right: 0;
            // background-color: rgba($color: $color-primary, $alpha: 0.6);
            // background-image: -webkit-gradient(linear, left top, right top, color-stop(0, #16162b), color-stop(12.5%, rgba(22, 22, 43, 0.875000125)), color-stop(25%, rgba(22, 22, 43, 0.75000025)), color-stop(37.5%, rgba(22, 22, 43, 0.625000375)), color-stop(50%, rgba(22, 22, 43, 0.5000005000000001)), color-stop(75%, rgba(22, 22, 43, 0.25000075)), to(rgba(22, 22, 43, 0.000001)));
            // background-image: -webkit-linear-gradient(left, #16162b 0, rgba(22, 22, 43, 0.875000125) 12.5%, rgba(22, 22, 43, 0.75000025) 25%, rgba(22, 22, 43, 0.625000375) 37.5%, rgba(22, 22, 43, 0.5000005000000001) 50%, rgba(22, 22, 43, 0.25000075) 75%, rgba(22, 22, 43, 0.000001) 100%);
            // background-image: linear-gradient(90deg, #16162b 0, rgba(22, 22, 43, 0.875000125) 12.5%, rgba(22, 22, 43, 0.75000025) 25%, rgba(22, 22, 43, 0.625000375) 37.5%, rgba(22, 22, 43, 0.5000005000000001) 50%, rgba(22, 22, 43, 0.25000075) 75%, rgba(22, 22, 43, 0.000001) 100%);
            // opacity: 0.95;

            background: -moz-linear-gradient(-45deg, rgba($color-primary, 0.8) 0%, rgba($color-primary, 0.5) 40%, rgba(0, 0, 0, 0) 100%);
            background: -webkit-linear-gradient(-45deg, rgba($color-primary, 0.8) 0%, rgba($color-primary, 0.6) 40%, rgba(0, 0, 0, 0) 100%);
            background: linear-gradient(135deg, rgba($color-primary, 0.8) 0%, rgba($color-primary, 0.6) 40%, rgba(0, 0, 0, 0) 100%);

            content: ' ';
        }
        img {
            width: 100%;
            object-fit: cover;
            object-position: center;
            height: 100%;
        }
    }
    .banner-content {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        padding: $px15 0;
        @include bp-above(md) {
            padding: $px30;
        }
        @include bp-above(lg) {
            // bottom: $px60;
            top: 50%;
            transform: translateY(-50%);
            bottom: auto;
        }
        h1 {
            color: $color-white;
            font-weight: 700;
            text-shadow: 1px 5px 8px rgba(0, 0, 0, 0.38);
            font-size: $px26;
            margin-bottom: $px15;
            @include bp-above(md) {
                font-size: $px40;
                margin-bottom: $px20;
            }
            @include bp-above(lg) {
                margin-bottom: $px30;
                font-size: $px60;
            }
        }
        h6 {
            font-weight: 400;
            font-size: $px14;
            @include bp-above(md) {
                font-size: $px20;
            }
        }
    }
    .banner-btn {
        border-radius: $px40;
        border: 2px solid #81d3f5;
        font-weight: 300;
        font-size: $px16;
        @include bp-above(lg) {
            font-size: $px20;
            padding-left: $px15;
            padding-right: $px15;
        }
        @include bp-above(lg) {
            font-size: $px24;
            padding-left: $px30;
            padding-right: $px30;
        }
    }
}

.secondary-holder {
    font-size: $px16;
    @include breakpoint(md) {
        font-size: $px18;
    }
    p {
        text-align: justify !important;
        font-size: $px16;
        @include breakpoint(md) {
            font-size: $px18;
        }
    }
}
.ml-hm {
    margin: 0 auto;
    max-width: 1200px;
    padding: 1rem 0 $px40;
    @include bp-above(lg) {
        padding: 4.9rem 0 $px40;
    }
    .heading-1 {
        margin-bottom: 64px;
        text-align: center;
        font-weight: 700;
        color: #384147;
        margin-bottom: 0;
        font-size: $px30;
        @include bp-above(md) {
            font-size: $px30;
        }
        @include bp-above(lg) {
            font-size: 42px;
        }

        .underline {
            display: inline-block;
            border-bottom: 4px solid $color-secondary;
        }
    }
}
.section-one {
    // background-color: #f9fafb;
    padding-top: $px30;
    padding-bottom: $px20;

    @include bp-above(lg) {
        padding-top: $px50;
        padding-bottom: $px40;
    }
    .proposition {
        margin-bottom: $px40;
        @include bp-above(lg) {
            margin-bottom: $px140;
        }
        &:last-child {
            margin-bottom: $px20;

            @include bp-above(lg) {
                margin-bottom: $px20;
            }
        }
    }
    .two-sections {
        padding: 0;
        overflow: hidden;

        figure {
            margin: 0;
            height: 100%;
            position: relative;
            // @include aspect-ratio(2, 1);
            img {
                // position: absolute;
                height: 100%;
                width: 100%;
                object-fit: cover;
                object-position: center;
                left: 0;
                top: 0;
            }
        }
        .content {
            text-align: left;
            font-size: $px18;
            line-height: 1.6;
            padding: $px24 0;
            color: #686875;
            @include bp-above(sm) {
                padding: $px30;
            }
            @include bp-above(lg) {
                padding: $px30 0;
            }
            .title-one {
                font-weight: 700;
                color: $color-black;
                text-transform: capitalize;
                margin-bottom: $px20;
                font-size: $px26;

                @include breakpoint(md) {
                    font-size: $px36;
                }
            }
            .title-two {
                font-weight: 700;
                color: $color-black;
                font-size: $px20;
                text-transform: capitalize;
                text-align: center;
                margin-bottom: $px30;
                line-height: 1.4;
            }
        }
    }
}
.ml-whyus {
    background-color: $color-primary;
    color: $color-white;
    padding-top: $px100;
    padding-bottom: $px50;

    .heading-1 {
        font-weight: 700;
        text-align: center;
        font-size: $px32;
        margin-bottom: $px48;

        @include bp-above(lg) {
            font-size: $px48;
            margin-bottom: $px100;
        }
    }
}
.why-ml-single {
    margin-bottom: 64px;
    display: flex;
    flex-wrap: wrap;
    figure {
        max-width: $px74;
        color: $color-white;
        svg {
            width: 100%;
            color: currentColor;
        }
    }
}

// .ml-home-action {
// }
.lm-icon {
    font-size: 22px;
    padding-top: 0;
    display: inline-block;
    line-height: 1;
    vertical-align: -4px;
}


// coming soon
.badge-num {
    position: relative;
    background: #ff0000;
    cursor:default;
    color: #fff;
    font-weight:300;
    font-size: 1rem;
    // height: 2rem;
    border-radius: $px5;
    padding-left: $px15;
    padding-right: $px15;
    margin-top:-1rem;
    margin-left:.1rem;
    border:.2rem solid #fff;
    text-align: center;
    display:inline-block;
    box-shadow: 1px 1px 5px rgba(0,0,0, .2);
    padding: 0.25rem 1rem;
    // animation: pulse 0.2s 1;
    &:after {
        position: absolute;
        top:-.1rem;
        left:-.1rem;
        border:2px solid rgba(255,0,0,.5);
        opacity:0;
        width:100%;
        height:100%;
        // animation: sonar 0.2s 1;
      }
  }
  
  @keyframes sonar { 
    0% {transform: scale(.8); opacity:1;}
    100% {transform: scale(1.3);opacity: 0;}
  }
  @keyframes pulse {
    0% {transform: scale(1);}
    20% {transform: scale(1.2); } 
    50% {transform: scale(.8);} 
    80% {transform: scale(1.1);} 
    100% {transform: scale(1);}
  }
  