$color-primary: #283e4a;
$color-primary-hover: #18c9e3;
$color-secondary: #0077b5;
$color-secondary-dark: #005b8b;
$color-third: #81d3f5;
$color-white: #fff;
$color-black: #333333;
$color-gray: #8b8b8b;
$color-dark-gray: #555555;
$color-lightgray: #f1f2f2;
$color-light-blue: #e1f5fd;
$color-border: #f1f2f2;
$color-yellow: #e98400;
