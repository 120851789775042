.user-card {
    padding: $px16;
    display: flex;
    position: relative;
    overflow: hidden;

    .MuiAvatar-circle {
        border: 2px solid #b3e4f9;
        width: $px164;
        height: $px164;
        &.mini-user-avtar {
            width: $px50;
            height: $px50;
        }
    }
    .user {
        &-title-name {
            font-weight: $bold;
            font-size: $px18;
            margin-bottom: $px10;
            color: $color-black;
            a {
                font-size: $px18;
            }
        }
        &-short-info {
            font-size: $px14;
            color: $color-gray;
            margin-bottom: $px10;
            font-weight: $regular;
        }
        &-site-link {
            font-size: $px14;
            font-weight: $medium;
            margin-bottom: $px10;
            a {
                font-size: $px14;
                color: $color-secondary;
                &:hover {
                    color: $color-secondary-dark;
                }
            }
        }
        &-inline-list {
            .MuiGrid-item {
                padding-right: $px20;
                color: $color-gray;
                position: relative;
                margin-bottom: $px8;
                a {
                    margin-left: $px4;
                    font-weight: $medium;
                    color: $color-secondary;
                    &:hover {
                        color: $color-secondary-dark;
                    }
                }
                &::after {
                    content: '|';
                    right: $px12;
                    top: 0;
                    position: absolute;
                }

                &:last-child {
                    padding-right: 0;
                    &::after {
                        display: none;
                    }
                }
            }
        }
        &-address {
            color: $color-gray;
            margin-bottom: $px10;
            font-weight: $regular;
        }
    }
    .card {
        &-top {
            padding: 0 0 $px10;
            margin-bottom: $px15;
            border-bottom: solid 1px $color-light-blue;
            .title {
                margin: 0;
                padding: 0;
                font-size: $px15;
                color: $color-black;
                font-weight: $medium;
            }
        }
    }
    &.stack-user-card {
        flex-direction: column;
        .MuiAvatar-root {
            width: $px80;
            height: $px80;
            margin-left: auto;
            margin-right: auto;
            margin-bottom: $px15;
        }
        .MuiCardContent-root {
            padding: 0;
        }
        .user {
            &-title-name {
                color: $color-black;
                font-size: $px14;
                font-weight: $bold;
                margin: 0 0 6px;
                text-align: center;
            }
            &-short-info {
                overflow: hidden;
                text-align: center;
                font-size: $px13;
                color: $color-gray;
                white-space: nowrap;
                text-overflow: ellipsis;
            }
        }
        .card-listing {
            border-top: solid 1px $color-lightgray;
            & > .list-group-item {
                padding-left: 0;
                padding-right: 0;
                color: $color-black;
                font-weight: $regular;
                font-size: $px14;
                .badge {
                    color: $color-secondary;
                    font-weight: $bold;
                    font-size: $px14;
                }
            }
        }
    }
    &.expert-card {
        background: transparent linear-gradient(180deg, var(#ffffff) 0%, rgba(238, 250, 255, 1) 100%) 0% 0% no-repeat padding-box;
        background: transparent linear-gradient(180deg, rgba(255, 255, 255, 1) 0%, rgba(238, 250, 255, 1) 100%) 0% 0% no-repeat padding-box;
    }
    .expert-ribbon {
        position: absolute;
        left: -$px23;
        top: -$px23;
        z-index: 1;
    }
    &.user-mini-card {
        .MuiAvatar-circle {
            width: $px60;
            height: $px60;
        }
        .user-title-name {
            font-size: $px24;
            color: $color-secondary;
            a {
                color: $color-secondary;
                text-decoration: none;
                cursor: pointer;
            }
        }
    }
}
.static-expert-list {
    &.MuiList-root {
        display: flex;
        justify-content: space-evenly;
        width: 100%;
        // padding-left: $px10;
        // padding-right: $px10;
        // margin-left: -$px10;
        // margin-right: -$px10;
    }
    li {
        padding-left: $px10;
        padding-right: $px10;
        margin-bottom: $px20;
    }
    .exp-card {
        box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.15), 0 2px 3px rgba(0, 0, 0, 0.2);
        border-radius: $px3;
        background-color: $color-white;
        padding: $px8;
        width: 100%;
        height: 100%;
        .head-1 {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: $px8;
            .icon-box {
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: $px4;
                padding: $px4;
                width: $px34;
                height: $px34;
                background-color: rgba($color-secondary, 0.25);
                margin-right: $px8;
                svg {
                    width: 100%;
                    color: $color-secondary;
                }
            }
            .number-box {
                color: $color-primary;
                font-size: $px24;
                line-height: 1.2;
                &.small {
                    font-size: $px12;
                    margin-top: $px14;
                }
            }
            &.star-rating {
                width: auto;
                .rating {
                    display: flex;
                    background-color: transparent;
                    svg {
                        color: $color-yellow;
                        max-width: 100%;
                        width: $px16;
                    }
                }
            }
        }
        .stat {
            margin: 0;
            color: $color-gray;
            font-size: $px14;
            text-align: center;
        }
    }
}
