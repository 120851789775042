form {
    max-width: 600px;
    margin: 0 auto;
}

input {
    display: block;
    box-sizing: border-box;
    width: 100%;
    border-radius: 4px;
    border: 1px solid black;
    padding: 10px 15px;
    margin-bottom: 10px;
    font-size: 14px;
}

textarea {
    display: block;
    box-sizing: border-box;
    width: 100%;
    border-radius: 4px;
    border: 1px solid black;
    padding: 10px 15px;
    margin-bottom: 10px;
    font-size: 14px;
    height: 120px;
}

label {
    line-height: 2;
    text-align: left;
    display: block;
    margin-bottom: 13px;
    margin-top: 20px;
    color: black;
    font-size: 14px;
    font-weight: 200;
}

button[type='submit'],
input[type='submit'] {
    background: $color-secondary;
    color: white;
    text-transform: uppercase;
    border: none;
    margin-top: 40px;
    padding: 20px;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 4px;
}

button[type='submit']:hover,
input[type='submit']:hover {
    background: $color-primary;
}

button[type='submit']:active,
input[type='button']:active,
input[type='submit']:active {
    transition: 0.3s all;
    transform: translateY(3px);
    border: 1px solid transparent;
    opacity: 0.8;
}

input:disabled {
    opacity: 0.4;
}

input[type='button']:hover {
    transition: 0.3s all;
}

button[type='submit'],
input[type='button'],
input[type='submit'] {
    -webkit-appearance: none;
}

.form-success {
    max-width: 600px;
    margin: 0 auto;
    text-align: center;
    min-height: 400px;
}
.ctct-inline-form{
  .ctct-form-defaults{
    padding: 0 !important;
    .ctct-form-custom{
      margin: 0;
    }
  }
}