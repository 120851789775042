.btn-main {
    //   font-size: $px14;
    color: #fff;
    background-color: $color-primary;
    border-color: $color-primary;
    font-weight: 400;
    &:hover {
        color: #fff;
        background-color: $color-secondary;
        border-color: $color-primary-hover;
    }
    &:focus,
    &.focus {
        color: #fff;
        background-color: $color-secondary;
        border-color: $color-primary-hover;
        box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
    }
    &.disabled,
    &:disabled {
        color: #fff;
        background-color: $color-primary;
        border-color: $color-primary;
    }
    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active,
    .show > .btn-main.dropdown-toggle {
        color: #fff;
        background-color: $color-primary-hover;
        border-color: $color-primary;
    }
    &:not(:disabled):not(.disabled):active:focus,
    &:not(:disabled):not(.disabled).active:focus,
    .show > .btn-main.dropdown-toggle:focus {
        box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
    }
}
.MuiIconButton-root {
    &.btn-icon {
        border: 1px solid #0077b5;
        width: 30px;
        height: 30px;
        background-color: $color-white;
        color: $color-secondary;
        border-radius: 50%;
        font-size: $px13;
        &:hover {
            border-color: $color-secondary-dark;
            color: $color-secondary-dark;
        }
    }
}
.card-link-bottom {
    color: $color-secondary;
    svg {
        @include transition(all, 0.2s, ease);
    }
    &:hover {
        color: $color-secondary-dark;

        svg {
            transform: translateX(2px);
        }
    }
}
.social-button {
    .btn-linkedin {
        background-color: $color-secondary;
        border-color: $color-secondary;
        color: $color-white;
        text-align: center;
        .MuiButton-label {
            display: flex;
            .MuiButton-endIcon {
                background-color: $color-primary;
                color: currentColor;
                margin-left: auto;
                margin-top: -$px8;
                margin-right: -$px11;
                margin-bottom: -$px8;
                width: $px44;
                height: $px44;
                border-radius: 0 $px6 $px6 0;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
        &:hover {
            background-color: $color-secondary-dark;
            border-color: $color-secondary-dark;
        }
    }

    .btn-google {
        background-color: #d9534f;
        border-color: #d9534f;
        color: $color-white;
        text-align: center;
        .MuiButton-label {
            display: flex;
            .MuiButton-endIcon {
                background-color: #cb2e25;
                color: currentColor;
                margin-left: auto;
                margin-top: -$px8;
                margin-right: -$px10;
                margin-bottom: -$px8;
                width: $px44;
                height: $px44;
                border-radius: 0 $px6 $px6 0;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
        &:hover {
            background-color: #dd372e;
            border-color: #dd372e;
        }
    }
}
