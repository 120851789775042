@mixin clearfix {
    &:before,
    &:after {
        content: "";
        display: table;
    }

    &:after {
        clear: both;
    }
}

/* @include transition(all,2s,ease-out); */
@mixin transition($what: all, $time: 0.2s, $how: ease-in-out) {
    -webkit-transition: $what $time $how;
    -moz-transition: $what $time $how;
    -ms-transition: $what $time $how;
    -o-transition: $what $time $how;
    transition: $what $time $how;
}

@mixin placeholder($color) {
    &::-moz-placeholder {
        color: $color;
        opacity: 1;
    }

    &:-ms-input-placeholder {
        color: $color;
    }

    &::-webkit-input-placeholder {
        color: $color;
    }
}

// Example
// .form-control {
//     $color: #ededed !default;
//     @include placeholder($color);
// }

@mixin linearGradient($top, $bottom) {
    background: $top; /* Old browsers */
    background: -moz-linear-gradient(top, $top 0%, $bottom 100%); /* FF3.6+ */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, $top), color-stop(100%, $bottom)); /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(top, $top 0%, $bottom 100%); /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(top, $top 0%, $bottom 100%); /* Opera 11.10+ */
    background: -ms-linear-gradient(top, $top 0%, $bottom 100%); /* IE10+ */
    background: linear-gradient(to bottom, $top 0%, $bottom 100%); /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#000000',GradientType=0 ); /* IE6-9 */
}

@mixin horizontal-linearGradient($left, $right) {
    background: $left; /* Old browsers */
    background: -moz-linear-gradient(left, $left 0%, $right 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(left, $left 0%, $right 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to right, $left 0%, $right 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#065596', endColorstr='#18c9e3',GradientType=1 ); /* IE6-9 */
}

// .button{
//     @include horizontal-linearGradient(#cccccc, #666666);
// }

@mixin border-radius($radius) {
    border-radius: $radius;
    -webkit-border-radius: $radius;
    -moz-border-radius: $radius;
}

// @include border-radius($radius);

@mixin linkeffect($typ, $tid, $ease) {
    -webkit-transition: $typ $tid $ease;
    -moz-transition: $typ $tid $ease;
    -o-transition: $typ $tid $ease;
    -ms-transition: $typ $tid $ease;
    transition: $typ $tid $ease;
}
