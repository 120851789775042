.hdd4 {
    font-size: $px16 !important;
    font-weight: $bold !important;
    padding-bottom: $px10 !important;
    &.primary {
        color: $color-primary !important;
    }
}

.hdd3 {
    font-size: $px16 !important;
    font-weight: $bold !important;
    margin-bottom: $px15 !important;
    svg {
        margin-right: $px15 !important;
    }
    &.primary {
        color: $color-primary !important;
    }
    &.secondary {
        color: $color-secondary !important;
    }
    &.line {
        font-size: $px20 !important;
        padding-bottom: $px10 !important;
        margin-bottom: $px20 !important;
        border-bottom: solid 1px $color-secondary;
    }
}
.ml-display-2 {
    font-size: $px32;
    text-align: center;
    margin-bottom: $px20;
    color: $color-black;
}
.fs-16 {
    font-size: $px16 !important;
}
.typo-center {
    padding-top: $px20;
    padding-bottom: $px20;
    .ml-display-2 {
        text-align: center;
        margin-bottom: $px20;
        color: $color-black;
        font-size: $px26;

        @include breakpoint(md) {
            font-size: $px32;
        }
    }
    .ml-display-3 {
        @extend .ml-display-2;
        color: $color-secondary;
        font-weight: 600;
        font-size: $px24;
        @include breakpoint(md) {
            font-size: $px32;
        }
    }
}
.text-black {
    color: $color-black !important;
}
.text-blue {
    color: $color-secondary !important;
}
