.setting-accordion {
    margin-bottom: $px15;
    .accordion-header {
        // padding-bottom: $px10;
        // border-bottom: solid 1px $color-border;
        // margin-bottom: $px15;
        .card-title {
            font-size: $px16;
            font-weight: $bold;
            color: $color-secondary;
            margin: 0;
        }
        .card-icon {
            margin-right: $px15;
        }
        &.Mui-expanded {
            // padding-bottom: $px10;
            border-bottom: solid 1px $color-border;
            // margin-bottom: $px15;
        }
        // .Mui-expanded {
        //     margin: 0;
        // }
    }
}
