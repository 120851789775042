.setting-listing {
    padding: $px10 0;
    width: 100%;
    .list-group-item {
        display: flex;
        flex-direction: column;
        padding-left: 0;
        padding-right: 0;
        border-bottom-color: $color-border;
        h6 {
            font-weight: $bold;
            font-size: $px14;
            margin-bottom: $px10;
        }
        .editable {
            .lft {
                font-size: $px14;
            }
        }
    }
}
