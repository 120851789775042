html {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
}
:root {
    font-size: 14px;
}
html,
body {
    height: 100%;
}
body {
    background-color: $color-white;
}
body,
p {
    font-family: $roboto !important;
}

a {
    color: $color-primary;
    text-decoration: none;
    @include transition(all, 0.2s, ease-in-out);
    &.underline {
        text-decoration: underline;
    }
    &:hover {
        color: $color-secondary;
        text-decoration: none !important;
    }
    &.primary {
        color: $color-primary;
    }
    &.secondary {
        color: $color-secondary;
    }
}

*:focus {
    outline: none !important;
}
input:focus,
button:focus,
a:focus {
    outline-width: 0 !important;
    outline-style: solid;
    outline-color: transparent !important;
}

button:focus,
a:focus {
    outline: none;
}

img {
    max-width: 100%;
}
.italic {
    font-style: italic;
}
// .container,
// .container-fluid,
// .container-sm,
// .container-md,
// .container-lg,
// .container-xl {
//     margin-right: -$px10 !important;
//     margin-left: -$px10 !important;
// }
