// .bgcolor-blue-light

.bgcolor {
    &-blue {
        background-color: $color-secondary;
        &-dark {
            background-color: $color-secondary-dark;
        }
        &-light {
            background-color: $color-light-blue;
        }
    }
}
