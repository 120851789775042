/* 
 * font-family: 'Poppins', sans-serif;
 * font-family: 'Roboto', sans-serif;
 */
@import url('https://fonts.googleapis.com/css?family=Poppins:400,400i,500,500i,600,600i,700,700i|Roboto:300, 300i,400,400i,500,500i,700,700i');

$roboto: 'Roboto', sans-serif;
$poppins: 'Poppins', sans-serif;
$regular: 400;
$medium: 500;
$bold: 700;
