.main-card {
    //   box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.2), 0px 0px 2px rgba(0, 0, 0, 0.2);
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.15), 0 2px 3px rgba(0, 0, 0, 0.2);
    border-radius: $px3;
    background-color: $color-white;
    padding: $px15;
    margin-bottom: 15px;
    &.card {
        padding-top: 0;
        padding-bottom: 0;
        border: 0;
        .card-header {
            background-color: $color-white;
            padding-left: 0;
            padding-right: 0;
        }
    }
}
.profile-card {
    .profile-pic {
        height: $px80;
        width: $px80;
        display: block;
        margin: 0 auto;
        margin-bottom: 15px;
        img {
            border: solid 2px $color-third;
            height: $px80;
            width: $px80;
            border-radius: 50%;
        }
    }
    .user-name {
        font-size: $px14;
        text-align: center;
        font-weight: $bold;
    }
    .user-title {
        font-size: $px13;
        text-align: center;
        font-weight: $regular;
        color: $color-gray;
    }
    .list-group-flush {
        > .list-group-item {
            padding-right: $px1;
            padding-left: $px1;
            display: flex;
            .bud {
                color: $color-secondary;
                font-weight: $bold;
            }
        }
    }
}
.prf-info {
    .card-header {
        position: relative;

        .prf-img {
            position: absolute;
            left: 0;
            top: 10px;
            width: $px46;
            height: $px46;
            img {
                border: 2px solid $color-secondary;
                width: $px46;
                height: $px46;
                border-radius: 50%;
            }
        }
        .prf-lft {
            position: relative;
            left: 0;
            margin-left: $px56;
            top: 0;
            h5 {
                color: $color-black;
                font-weight: 500;
                margin: 0 0 3px;
                font-size: $px16;
            }
            h6 {
                font-size: $px13;
                color: $color-gray;
                font-weight: 400;
                margin: 0;
            }
            time {
                font-size: $px13;
                color: $color-gray;
                font-weight: 400;
                margin: 0 0 5px;
            }
        }
    }
    .card-body {
        .card-title {
            color: $color-secondary;
            font-weight: 700;
            margin: 0 0 10px;
            font-size: $px16;
        }
        .card-question {
            color: $color-black;
            font-weight: 700;
            margin: 0 0 10px;
            font-size: $px16;
            position: relative;
            padding-left: $px24;
            .icon-q {
                position: absolute;
                left: 0;
                top: 0;
                width: $px14;
                height: $px14;
            }
        }
        .card-answer {
            color: $color-black;
            font-weight: 400;
            margin: 0 0 10px;
            font-size: $px16;
            position: relative;
            padding-left: $px24;
            .icon-a {
                position: absolute;
                left: 0;
                top: 0;
                width: $px14;
                height: $px14;
            }

            a {
                color: #0077b5;
            }
        }
        .hashtag {
            padding-left: $px24;
            color: #0077b5;
            a {
                color: #0077b5;
            }
        }
    }
    .card-footer {
        background-color: transparent;
        padding-left: 0;
        padding-right: 0;
        .inline-list {
            display: inline-block;
            list-style: none;
            padding: 0;
            margin: 0;
            li {
                display: inline-block;
                position: relative;
                &::after {
                    content: '\000B7';
                    position: relative;
                    font-size: 22px;
                    line-height: 22px;
                    height: 20px;
                    overflow: hidden;
                    display: inline-block;
                    vertical-align: middle;
                }
                &:last-child {
                    &::after {
                        margin-left: 0;
                        content: '';
                    }
                }
            }
        }

        .inline-list-r {
            display: inline-block;
            list-style: none;
            padding: 0;
            margin: 0;
            li {
                display: inline-block;
                position: relative;
                button {
                    padding-left: 3px;
                    padding-right: 3px;
                    span {
                        vertical-align: middle;
                        font-size: 18px;
                        margin-right: 0;
                    }
                    &.dropdown-toggle {
                        &::after {
                            display: none;
                        }
                    }
                }
                .dropup {
                    .dropdown-item {
                        color: $color-secondary;
                        .material-icons {
                            padding-right: 5px;
                            font-size: 18px;
                            margin-top: 1px;
                            vertical-align: top;
                        }
                    }
                }
            }
        }
        .btn-light {
            background-color: transparent;
            border-color: transparent;
            > span {
                vertical-align: middle;
                display: inline-block;
                height: 16px;
                img,
                svg {
                    vertical-align: top;
                }
            }
            &:hover,
            &.active {
                color: $color-secondary;
                background-color: transparent;
                border-color: transparent;
                svg {
                    path {
                        fill: $color-secondary;
                    }
                }
            }
        }
    }
}

.ml-card {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.15), 0 2px 3px rgba(0, 0, 0, 0.2);
    border-radius: $px3;
    background-color: $color-white;
    padding: $px15;
    margin-bottom: $px20;

    .ml-card {
        &-header {
            padding-bottom: $px10;
            border-bottom: solid 1px $color-border;
            margin-bottom: $px15;
        }
        &-title {
            font-size: $px16;
            font-weight: $bold;
            color: $color-secondary;
            margin: 0;
        }
        &-icon {
            margin-right: $px15;
        }
        // &-body {
        // }
    }
    &.exp-list {
        ul {
            padding-top: 0;
            padding-bottom: 0;
            margin-bottom: $px15;
            li {
                display: flex;
                padding-right: 0;
                padding-left: 0;
                a {
                    display: flex;
                    max-width: 100%;
                    h4 {
                        font-weight: $medium;
                        font-size: $px14;
                        color: $color-black;
                        margin-bottom: $px3;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        max-width: 100%;
                        overflow: hidden;
                    }
                    p {
                        color: $color-primary;
                        font-size: $px13;
                        margin: 0;
                    }
                }
            }
        }
    }
}
.notification-box {
    ul {
        margin-left: -$px15;
        margin-right: -$px15;
        li {
            padding-left: $px15;
            padding-right: $px15;
            // padding-left: 0;
            // padding-right: 0;
            .notification {
                display: flex;
                // border-bottom: solid 1px $color-primary-hover;
                .notification-icon {
                    min-width: $px26;
                    margin-right: $px5;
                }
                a {
                    font-weight: $medium;
                    color: $color-secondary;
                }
                p {
                    margin: 0;
                    small {
                        color: $color-gray;
                        font-size: $px12;
                    }
                }
            }
            &.unread {
                background-color: rgba($color-secondary, 0.15);
            }
        }
    }
}
