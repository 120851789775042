.blue-box {
    font-size: $px14;
    p {
        margin-bottom: $px15;
    }
}
.border-top,
.border-left,
.border-bottom,
.border-right {
    border-color: $color-lightgray !important;
}
.bullet-list {
    li {
        font-size: $px16;
        margin-bottom: $px30;
        font-weight: 700;
        p {
            font-weight: 400;
        }
    }
}

.number {
    list-style-type: decimal;
    li {
        font-size: $px16;
        margin-bottom: $px20;
    }
}
.alpha {
    list-style-type: lower-alpha;
    li {
        font-size: $px16;
        margin-bottom: $px20;
    }
}
.roman {
    list-style-type: lower-roman;
    li {
        font-size: $px16;
        margin-bottom: $px20;
    }
}
.MuiButton-containedPrimary:hover {
    background-color: #00537e;
    color: $color-white;
}
.punchline {
    display: inline-block;
    .content {
        position: relative;
        overflow: hidden;
        // line-height: 60px;
        // color: #ecf0f1;
        // font-size: 52px;
        // font-family: 'Montserrat', sans-serif;
        // font-weight: 900;
        // color: #f7be34;
        // text-transform: uppercase;
        // margin-bottom: 30px;
        // max-width: 520px;
        // margin: 0 auto;
        // @include breakpoint(md) {
        //     max-width: 480px;
        // }
        // @include breakpoint(xl) {
        //     max-width: 680px;
        // }
        &__container {
            font-weight: 600;
            overflow: hidden;
            height: 52px;
            padding: 0 0 0;
            position: relative;
            &__text {
                text-transform: uppercase;
                display: block;
                margin: 0;
                line-height: 18px;
                font-size: 18px;
                color: $color-white;
                letter-spacing: 1px;
                text-align: left;
                margin-bottom: 30px;
                font-weight: 700;
            }
            &__list {
                margin-top: 0;
                text-align: left;
                list-style: none;
                -webkit-animation-name: change;
                animation-name: change;
                -webkit-animation-duration: 10s;
                animation-duration: 10s;
                -webkit-animation-iteration-count: infinite;
                animation-iteration-count: infinite;
                -webkit-animation-iteration-count: 1;
                animation-iteration-count: 1;
                position: relative;
                left: 0;
                top: 0;
                right: 0;
                padding: 0;
                margin: 0;
                &__item {
                    text-align: center;
                    margin: 0;
                    line-height: 1.8;

                    @include bp-above(md) {
                        text-align: left;
                    }
                    @include bp-above(lg) {
                        text-align: left;
                        line-height: 1.29;
                    }
                }
            }
        }
    }
}

// @-webkit-keyframes opacity {
//     0%,
//     100% {
//         opacity: 0;
//     }
//     50% {
//         opacity: 1;
//     }
// }

// @-webkit-keyframes change {
//     0%,
//     12.66%,
//     100% {
//         transform: translate3d(0, 0, 0);
//     }
//     16.66%,
//     29.32% {
//         transform: translate3d(0, -25%, 0);
//     }
//     33.32%,
//     45.98% {
//         transform: translate3d(0, -50%, 0);
//     }
//     49.98%,
//     62.64% {
//         transform: translate3d(0, -75%, 0);
//     }
//     66.64%,
//     79.3% {
//         transform: translate3d(0, -50%, 0);
//     }
//     83.3%,
//     95.96% {
//         transform: translate3d(0, -25%, 0);
//     }
// }

// @-o-keyframes opacity {
//     0%,
//     100% {
//         opacity: 0;
//     }
//     50% {
//         opacity: 1;
//     }
// }

// @-o-keyframes change {
//     0%,
//     12.66%,
//     100% {
//         transform: translate3d(0, 0, 0);
//     }
//     16.66%,
//     29.32% {
//         transform: translate3d(0, -25%, 0);
//     }
//     33.32%,
//     45.98% {
//         transform: translate3d(0, -50%, 0);
//     }
//     49.98%,
//     62.64% {
//         transform: translate3d(0, -75%, 0);
//     }
//     66.64%,
//     79.3% {
//         transform: translate3d(0, -50%, 0);
//     }
//     83.3%,
//     95.96% {
//         transform: translate3d(0, -25%, 0);
//     }
// }

// @-moz-keyframes opacity {
//     0%,
//     100% {
//         opacity: 0;
//     }
//     50% {
//         opacity: 1;
//     }
// }

// @-moz-keyframes change {
//     0%,
//     12.66%,
//     100% {
//         transform: translate3d(0, 0, 0);
//     }
//     16.66%,
//     29.32% {
//         transform: translate3d(0, -25%, 0);
//     }
//     33.32%,
//     45.98% {
//         transform: translate3d(0, -50%, 0);
//     }
//     49.98%,
//     62.64% {
//         transform: translate3d(0, -75%, 0);
//     }
//     66.64%,
//     79.3% {
//         transform: translate3d(0, -50%, 0);
//     }
//     83.3%,
//     95.96% {
//         transform: translate3d(0, -25%, 0);
//     }
// }

@keyframes opacity {
    0%,
    100% {
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
}

// @keyframes change {
//     0%,
//     12.66%,
//     100% {
//         transform: translate3d(0, 0, 0);
//     }
//     16.66%,
//     29.32% {
//         transform: translate3d(0, -25%, 0);
//     }
//     33.32%,
//     45.98% {
//         transform: translate3d(0, -50%, 0);
//     }
//     49.98%,
//     62.64% {
//         transform: translate3d(0, -75%, 0);
//     }
//     66.64%,
//     79.3% {
//         transform: translate3d(0, -50%, 0);
//     }
//     83.3%,
//     95.96% {
//         transform: translate3d(0, -25%, 0);
//     }
// }
@keyframes change {
    0%,
    10%,
    100% {
        transform: translate3d(0, 0, 0);
    }
    15%,
    40% {
        transform: translate3d(0, -25%, 0);
    }
    45%,
    75% {
        transform: translate3d(0, -50%, 0);
    }
    80%,
    95% {
        transform: translate3d(0, -75%, 0);
    }
}
.long-form-text{
    p{
        text-align: justify;
    }
}