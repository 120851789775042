.exp-section {
    border-bottom: solid $px1 $color-lightgray;
    padding-bottom: $px16;
    margin-bottom: $px16;

    &:last-child {
        border-bottom: none;
        padding-bottom: 0;
        margin-bottom: 0;
    }
}
