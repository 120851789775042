.prelogin-left {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $color-dark-gray;
    height: 100%;
    flex-direction: column;
    padding: $px20;
}
.prelogin-footer {
    margin-top: auto;
    color: $color-white;
    margin-bottom: $px40;
    .footer-link {
        display: flex;
        justify-content: center;
        margin-bottom: $px10;
        a {
            width: auto;
            display: flex;
            font-size: $px13;
            color: $color-white;
            margin-right: $px6;
            padding: 0;
            justify-content: center;
            align-items: center;
            &::after {
                margin-left: $px6;
                content: '·';
                margin-top: auto;
                margin-bottom: auto;
                line-height: 1;
            }
            &:last-child {
                &::after {
                    display: none;
                }
            }
        }
    }
}
.prelogin-right {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    margin-right: auto;
    max-width: 500px;
    width: 100%;
    @include breakpoint(xl) {
        max-width: 660px;
    }

    .prelogin-section {
        .prelogin-paper {
            box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.2);
            border-radius: $px3;
            padding: $px30;
            background-color: $color-white;
        }
        h1 {
            font-size: $px36;
            font-weight: $medium;
            text-align: center;
            margin-bottom: $px30;
            color: $color-secondary;
        }
    }
}
.or-devider {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    &::before {
        content: ' ';
        height: 1px;
        left: 0;
        right: 0;
        top: 50%;
        position: absolute;
        transform: translateY(-50%);
        background-color: $color-border;
    }
    span {
        position: relative;
        z-index: 1;
        width: $px36;
        height: $px36;
        border-radius: 50%;
        border: solid 1px $color-border;
        background-color: $color-white;
        font-size: $color-dark-gray;
        font-weight: $medium;
        text-transform: uppercase;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
