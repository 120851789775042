.header {
    background-color: $color-primary;
    margin-bottom: $px15;
    header {
        background-color: $color-primary;
        box-shadow: none;
    }
}
.top-header {
    @include transition(all, 0.2s, ease-out);
    background-color: $color-primary;
    // margin-bottom: $px15;
    header {
        @include transition(all, 0.2s, ease-out);
        // box-shadow: none;
    }
}
.prelogin-header {
    @include transition(all, 0.2s, ease-out);
    z-index: 10;
    left: 0;
    right: 0;
    top: 0;
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: $color-primary;
    position: relative;
    @include breakpoint(md) {
        position: fixed;
        background-color: transparent;
    }
    .prelogin-menu {
        button,
        a {
            color: $color-white;
            font-size: $px13;
            padding: 6px 6px;
            @include breakpoint(md){
                font-size: $px14;
                padding: 6px 8px;
            }
        }
    }
    .prelog-logo-box {
        img {
            height: $px48;
            @include transition(all, 0.2s, ease-out);
        }
    }
    &.sticky {
        @include transition(all, 0.2s, ease-out);
        background-color: $color-primary;
        padding-top: 0;
        padding-bottom: 0;
        position: fixed;
        .prelog-logo-box {
            img {
                height: $px42;
            }
        }
    }
}
.transparent-menu {
    .MuiMenu-paper {
        background: rgba(40, 62, 74, 0.8);
        box-shadow: 0 8px 32px 0 rgba(58, 58, 58, 0.37);
        backdrop-filter: blur(4px);
        -webkit-backdrop-filter: blur(4px);
        border-radius: 4px;
        border: 1px solid rgba(70, 71, 72, 0.18);
        margin-left: 8px;
        a {
            color: $color-white !important;
        }
        .MuiDivider-root {
            background-color: rgba(255, 255, 255, 0.6);
        }
    }
}
