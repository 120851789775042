.footer-links {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
    padding: 0;
    list-style: none;
    margin: 0;
    li {
        position: relative;
        display: flex;
        align-items: center;
        &::after {
            content: '·';
            padding: 0 $px5;
        }
        &:last-child {
            &::after {
                display: none;
            }
        }
    }
}

.main-footer {
    border-top: solid 1px $color-primary;
    padding-top: $px15;
    padding-bottom: $px15;
    // margin-left: -$px10;
    // margin-right: -$px10;
    .social-list {
        display: flex;
        justify-content: center;
        @include bp-above(lg) {
            justify-content: flex-end;
        }
        a {
            color: $color-primary;
            text-align: center;
            margin: 0 $px10;
            padding: 0 $px5;

            &:hover {
                color: $color-secondary;
            }
        }
    }
    .footer-list {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: $px15;
        justify-content: center;

        @include bp-above(lg) {
            margin-bottom: 0;
        }
        a {
            margin: 0 $px15;
            font-size: $px14;
            &:first-child {
                margin-left: 0;
            }
            &:last-child {
                margin-right: 0;
            }
        }
    }
}
