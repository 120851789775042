.social-icon-list {
    display: flex;
    .MuiGrid-item {
        padding: 0 $px18 0 0;
        position: relative;
        margin-right: $px15;
        .MuiLink-root {
            color: $color-secondary;
            font-size: $px16;

            &:hover {
                color: $color-secondary-dark;
            }
        }
        &::after {
            font-family: 'Font Awesome 5 Free';
            font-weight: 900;
            position: absolute;
            right: 0;
            top: 50%;
            margin-top: -1px;
            // transform: translateY(-50%);
            content: '\f111';
            color: $color-gray;

            font-size: $px4;
            line-height: 1;
        }
        &:last-child {
            padding-right: 0;
            margin-right: 0;
            &::after {
                display: none;
            }
        }
    }
}

.profile-share-button {
    position: absolute;
    right: $px15;
    top: $px15;
}
.share-panel {
    .share-dropdown {
        padding: $px15;
        border-radius: $px15;
    }
    .MuiPopover-paper {
        box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.15);
        border: 1px solid rgba(241, 242, 242, 1);
        border-radius: 4px;

        h6 {
            color: $color-primary;
            font-size: $px14;
            font-weight: $bold;
            letter-spacing: -0.35px;
            margin-bottom: $px10;
        }
        .social-icon-list {
            border-top: solid 1px $color-border;
            border-bottom: solid 1px $color-border;
            padding: $px5;

            .MuiGrid-item {
                padding: 0 $px8 0 0;
                position: relative;
                margin-right: $px8;

                &::after {
                    margin-top: 0;
                    content: ' ';
                    color: $color-gray;
                    background-color: $color-border;
                    width: 1px;
                    height: 100%;
                    top: 50%;
                    transform: translateY(-50%);
                }
                &:last-child {
                    &::after {
                        display: none;
                    }
                }
            }
        }
        .sharelink-box {
            margin: $px10 0 0;
            position: relative;
            .copy-link {
                position: absolute;
                left: $px4;
                top: 0;
                bottom: 0;
                width: $px24;
                display: flex;
                align-items: center;
                justify-content: center;
                z-index: 1;
            }
            input {
                padding-left: $px32;
            }
        }
    }
}
