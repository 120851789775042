$breakpoint: (
    xs: 24em,
    // extra small 384
    lt-sm: 35.938em,
    //575px
    sm: 36em,
    // 576px
    md: 48em,
    // 768px
    lg: 62em,
    // 992px
    xl: 75em,
    // 1200px
    tablet: 47.938em,
    // 767px
    lt: 61.938em,
    // 991px
);

@mixin breakpoint($bp, $rule: min-width) {
    @media screen and (#{$rule}: map-get($breakpoint, $bp)) {
        @content;
    }
}

// @include breakpoint(m) {
//         width: 48%;
//  }
// @include breakpoint(m, max-width) {
//         width: 100%;
//  }

/* 
Custom gutter implementation
*/
$grid-gutter-widths: (
    xs: 10px,
    md: 20px,
    lg: 20px,
) !default;

@each $grid-breakpoint, $grid-gutter-width in $grid-gutter-widths {
    @include media-breakpoint-up($grid-breakpoint) {
        $grid-gutter-half-width: $grid-gutter-width / 2;
        .container,
        .container-fluid,
        .container-sm,
        .container-md,
        .container-lg,
        .container-xl {
            padding-right: $grid-gutter-half-width;
            padding-left: $grid-gutter-half-width;
        }
    }
}

@each $grid-breakpoint, $grid-gutter-width in $grid-gutter-widths {
    @include media-breakpoint-up($grid-breakpoint) {
        $grid-gutter-half-width: $grid-gutter-width / 2;
        .row:not(.no-gutters) {
            margin-right: -$grid-gutter-half-width;
            margin-left: -$grid-gutter-half-width;
        }
        .row:not(.no-gutters) > .col,
        .row:not(.no-gutters) > [class*='col-'] {
            padding-right: $grid-gutter-half-width;
            padding-left: $grid-gutter-half-width;
        }
    }
}

@each $grid-breakpoint, $grid-gutter-width in $grid-gutter-widths {
    @include media-breakpoint-up($grid-breakpoint) {
        $grid-gutter-half-width: $grid-gutter-width / 2;
        .row:not(.no-gutters) {
            margin-right: -$grid-gutter-half-width;
            margin-left: -$grid-gutter-half-width;
        }
        .row:not(.no-gutters) > .col,
        .row:not(.no-gutters) > [class*='col-'] {
            padding-right: $grid-gutter-half-width;
            padding-left: $grid-gutter-half-width;
        }
    }
}
