.top-inner-banner {
    position: relative;
    // margin-left: -$px10;
    // margin-right: -$px10;
    margin-bottom: $px60;
    figure {
        width: 100%;
        position: relative;
        margin: 0;
        img {
            width: 100%;
            height: 200px;
            object-fit: cover;
            object-position: center;
        }
        &::after {
            background: linear-gradient(180deg, rgba(40, 62, 74, 1) 0%, rgba(8, 12, 15, 0) 100%);
            position: absolute;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
            content: ' ';
        }
    }
    .inner-banner-content {
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        .text-display {
            text-transform: capitalize;
            text-align: center;
            color: $color-white;
            font-weight: 700;
            letter-spacing: 2px;
            font-size: $px18;
            margin-top: $px20;
            @include breakpoint(md) {
                font-size: $px24;
            }
            @include breakpoint(xl) {
                margin-top: 0;
                font-size: $px40;
            }
        }
    }
    &.top-lg {
        figure {
            img {
                height: 350px;
            }
        }
    }
}
.meet-the-team {
    .member {
        font-size: $px14;
        color: $color-black;
        text-align: center;
        margin-bottom: $px30;
        figure {
            width: 220px;
            height: 220px;
            border-radius: 50%;
            display: block;
            margin: 0 auto $px30;
        }
        h3 {
            color: $color-black;
            margin-bottom: $px15;
            text-align: center;
            text-transform: uppercase;
            font-size: $px20;

            font-weight: $medium;
        }
        h4 {
            color: $color-black;
            margin-bottom: $px15;
            text-align: center;
            text-transform: uppercase;
            font-size: $px15;
            font-weight: $regular;
        }
    }
}
.trending-insights {
    .trending-box {
        h2 {
            font-weight: 600;
            border-bottom: solid 2px $color-secondary;
            margin-bottom: $px5;
            padding-bottom: $px15;
            color: $color-secondary;
            font-size: $px18;
            @include breakpoint(lg) {
                font-size: $px22;
            }
        }
    }
    .trending-list {
        list-style: none;
        padding: 0;
        margin: 0 0 $px15;
        li {
            padding-right: 0;
            padding-left: 0;
            a {
                background: url(../../assets/images/arrow_forward.svg) no-repeat center right;
                padding-right: $px30;
                position: relative;
                padding-right: 30px;
                width: 100%;
                margin-bottom: $px15;
                padding-bottom: $px15;
                border-bottom: solid 1px $color-border;
                &:last-child {
                    margin-bottom: 0;
                    // padding-bottom: 0;
                    // border-bottom: 0;
                }
                & > svg {
                    position: absolute;
                    top: 50%;
                    right: 0;
                    transform: translateY(-50%);
                }

                .trnd-que {
                    background: url(../../assets/images/Q.svg) no-repeat center left;
                    padding-left: $px26;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    font-weight: 700;
                    margin-bottom: $px10;
                    color: #333333;
                }
                .trnd-ans {
                    background: url(../../assets/images/A.svg) no-repeat center left;
                    padding-left: $px26;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    font-weight: 400;
                    color: #333333;
                }
                &:hover {
                    background-position: right 5px center;
                }
            }
        }
    }
}
.loadMore {
    border: solid 1px $color-secondary;
    border-radius: 5px;
    color: #0077b5;

    font-weight: 600;
    font-size: $px16;
    text-transform: uppercase;
    padding: $px10 $px30;
}
.w12 {
    max-width: 100%;
    width: 1200px;
}
.w10 {
    max-width: 100%;
    width: 1000px;
}
// Posts

.post-archive-list-item-wrapper {
    margin-bottom: 15px;
}
@media (min-width: 768px) {
    .post-archive-list-item-wrapper {
        margin-bottom: 0;
        align-items: stretch;
        justify-content: stretch;
        display: flex;
        width: 100%;
    }
}

.post-archive-list-item {
    background: #fff;
    width: 100%;
    box-shadow: 0 0 16px rgba(15, 18, 43, 0);
    transition: all 0.2s ease;
    display: flex;
    flex-direction: row;
}
.post-archive-list-item:hover {
    box-shadow: 0 0 16px rgba(15, 18, 43, 0.1);
}
@media (min-width: 768px) {
    .post-archive-list-item {
        display: flex;
        flex-direction: column;
    }
}
.post-archive-list-item-image {
    display: block;
    // background-image: url(/assets/img/insight-default-image.5e7e2e31.jpg);
    background-size: cover;
    background-position: 50%;
    background-color: #f7f6fb;
    width: 33%;
    min-height: 150px;
    flex: 1 1 0;
    overflow: hidden;
    position: relative;
}
.post-archive-list-item-image img {
    position: absolute;
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
}
@media (min-width: 768px) {
    .post-archive-list-item-image {
        width: 100%;
        min-height: 250px;
    }
}
.post-archive-list-item-content {
    padding: 20px;
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
}
@media (max-width: 767.98px) {
    .post-archive-list-item-content {
        flex: 1 1 0;
    }
}
.post-archive-list-item-category {
    font-size: 12px;
    font-weight: 900;
    color: #0f122b;
    text-transform: uppercase;
    margin-bottom: 10px;
}
.post-archive-list-item-category:focus,
.post-archive-list-item-category:hover {
    text-decoration: none;
    opacity: 0.5;
}
.post-archive-list-item-title {
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 20px;
    text-transform: capitalize;
}
.post-archive-list-item-title a {
    color: #0f122b;
}
.post-archive-list-item-title a:focus,
.post-archive-list-item-title a:hover {
    color: #0f122b;
    text-decoration: none;
    opacity: 0.5;
}
.post-archive-list-item-date {
    font-weight: 600;
    color: #526175;
    opacity: 0.7;
}
.post-archive-list-item-author {
    margin-top: -15px;
    font-size: 16px;
    font-weight: 600;
}
.post-archive-list-item-image + .post-archive-list-item-content .post-archive-list-item-excerpt {
    display: none;
}
.post-archive-list-item-tags {
    font-size: 12px;
    margin-top: auto;
}
.post-archive-list-item-tag {
    color: #0f122b;
}
.post-archive-list-item-tag:focus,
.post-archive-list-item-tag:hover {
    color: #adb5c2;
    text-decoration: none;
}
.post-archive-filter {
    padding: 30px;
    background: #526175;
    color: #fff;
    position: relative;
    min-height: 118px;
    @include bp-above(md) {
        padding: 30px 60px;
    }
    .block-accent {
        height: 8px;
        left: 0;
        right: 0;
        top: 0;
        position: absolute;
        background-color: #26b546;
    }
    .heading-1 {
        @extend .heading-1;
        color: $color-white;
    }
}
.insights-wrapper {
    background-color: #e1e5eb;
}
.bpost-wrapper {
    padding-top: $px20;
    // padding-top: $px80;
    padding-left: $px15;
    padding-right: $px15;
    @include bp-above(lg) {
        padding-left: 0;
        padding-right: 0;
    }
    .insight-content-title {
        margin-bottom: 30px;
        font-size: $px36;
        font-weight: 700;
        color: $color-black;
        text-transform: capitalize;
    }
    .insight-content-author,
    .insight-content-read-time,
    .insight-content-subtitle {
        font-size: 18px;
        font-weight: 500;
    }
}

.insights-list-item-tags {
    font-size: 12px;
    margin-top: auto;
    span {
        display: inline-block;
        & + span {
            margin-left: $px5;
        }
    }
}
.insights-list-item-tag {
    color: #0f122b;
    &:focus,
    &:hover {
        color: #adb5c2;
        text-decoration: none;
    }
}
.insights-list-item-type {
    font-size: 12px;
    color: rgb(15, 18, 43);
    // text-transform: uppercase;
    margin-bottom: 10px;
}
.insight-content-top {
    margin-bottom: 30px;
    border-bottom: 1px solid rgba(173, 181, 194, 0.5);
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}
@media (min-width: 992px) {
    .insight-content-top {
        padding: 30px 0;
        margin: 0 0 30px;
    }
}
.insight-share-link {
    font-size: 14px;
    color: #0f122b;
    padding: 5px;
}
.insight-share-link:focus,
.insight-share-link:hover {
    color: #adb5c2;
    text-decoration: none;
}
