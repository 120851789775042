.user-states-holder {
    margin-bottom: $px20;
    .state-box {
        border-right: solid 1px $color-lightgray;
        padding-top: $px10;
        padding-bottom: $px10;
        text-align: center;

        h3 {
            font-size: $px22;
            font-weight: $regular;
            color: $color-secondary;
        }
        h4 {
            font-size: $px16;
            font-weight: $regular;
            color: $color-primary;
        }

        &:last-child {
            border-right: 0;
        }
    }
}
