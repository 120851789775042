$px1: 0.07143rem;
$px2: 0.1429rem;
$px3: 0.2143rem;
$px4: 0.2857rem;
$px5: 0.3571rem;
$px6: 0.4286rem;
$px7: 0.5rem;
$px8: 0.5714rem;
$px9: 0.6429rem;
$px10: 0.7143rem;
$px11: 0.7857rem;
$px12: 0.8571rem;
$px13: 0.9286rem;
$px14: 1rem;
$px15: 1.071rem;
$px16: 1.143rem;
$px18: 1.286rem;
$px20: 1.429rem;
$px22: 1.571rem;
$px23: 1.643rem;
$px24: 1.714rem;
$px26: 1.857rem;
$px28: 2rem;
$px30: 2.143rem;
$px32: 2.286rem;
$px34: 2.429rem;
$px36: 2.571rem;
$px38: 2.714rem;
$px40: 2.857rem;
$px41: 2.929rem;
$px42: 3rem;
$px43: 3.071rem;
$px44: 3.143rem;
$px46: 3.286rem;
$px48: 3.429rem;
$px50: 3.571rem;
$px52: 3.714rem;
$px54: 3.857rem;
$px56: 4rem;
$px58: 4.143rem;
$px60: 4.286rem;
$px72: 5.143rem;
$px74: 5.286rem;
$px78: 5.571rem;
$px80: 5.714rem;
$px82: 5.857rem;
$px84: 6rem;
$px86: 6.143rem;
$px88: 6.286rem;
$px90: 6.429rem;
$px92: 6.571rem;
$px94: 6.714rem;
$px96: 6.857rem;
$px98: 7rem;
$px100: 7.143rem;
$px126: 9rem;
$px140: 10rem;
$px164: 11.714rem;
